/* global Component */
class NotificationComponent extends Component {

    static name() {
        return "NotificationComponent";
    }

    static componentName() {
        return "NotificationComponent";
    }

    data() {
        return {
            title: 0,
            message: 0,
            type: null,
            visible: false,
            defaultIcons: {
                success: "/static/ecommerce/img/success.png",
                info: "/static/ecommerce/img/info.png",
                warning: "/static/ecommerce/img/warning.png",
                error: "/static/ecommerce/img/error.png",
            },
            defaultBackground: {
                success: "#28a745",
                info:"#00a9c9",
                warning: "#F89406",
                error: "#BD362F",
            },
        };
    }

    mounted() {
        return function () {
            this.subscribeEvent('toast',this.callback);
        };
    }

    getComputed() {
        return {
            iconSrc() {
                return this.defaultIcons[this.type];
            },
            toastBackgroundColor() {
                return this.defaultBackground[this.type];
            },
        };
    }

    getMethods(){
        return {
            callback:this.callback
        };
    }

    callback(obj){
        //console.log('mounted Notification Component');
        this.visible = true;
        this.title = this.tr(obj.title);
        this.message = this.tr(obj.message);
        this.type = obj.type;
        setTimeout(()=> this.visible = false , 2500);
    }

    getTemplate() {
        return `<transition name="slide-fade" v-if="visible">
                    <div class="notification"  :style="{backgroundColor:toastBackgroundColor}">
                        <div class="notification-icon">
                            <img :src="iconSrc"/>
                        </div>
                        <div class="notification-body">
                            <div class="notification-title">{{tr(title)}}</div>
                            <div class=" notification-message">{{tr(message)}}</div>
                        </div>
                    </div>
                </transition>`;
    }
}

NotificationComponent.registerComponent();